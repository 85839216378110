<template>
  <div v-loading="loading" class="pdf p-20">
    <div v-if="showImg" class="img-box">
      <img :src="imgUrl" alt="" class="img" />
    </div>
    <template v-else-if="isShowPdf">
      <!-- <pdf
        v-for="i in pageCount"
        :key="i"
        :src="src"
        :page="i"
        class="pdf-img"
      /> -->
      <iframe :src="src" frameborder="0" width="100%" height="600px"></iframe>
    </template>
    <iframe
      v-else
      :src="src"
      width="100%"
      height="600px"
      frameborder="1"
    ></iframe>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import store from "@/store";
export default {
  components: { pdf },
  props: {
    fileId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      src: "", // pdf文件地址
      header: {
        Authorization: store.getters.token
      },
      imgType: ["png", "jpg", "jpeg", "bmp", "gif", "webp"],
      pdfType: ["pdf", "PDF"],
      officeType: ["doc", "xls", "ppt", "docx", "xlsx", "pptx"],
      showImg: false,
      isShowPdf: false,
      isShowOffice: false,
      imgUrl: "",
      loading: true
    };
  },
  computed: {
    // xsrc() {
    //   // return  `https://view.officeapps.live.com/op/view.aspx?src=http://121.36.82.187:8204/2021/3/2a5f38b1-330f-4614-8bc4-77b85ab59515.xlsx`
    //   // return 'https://view.officeapps.live.com/op/view.aspx?src=http://dev.2h.ve-city.com/files/2021/3/体育部-部门分解任务导入表.xlsx'
    //   return `https://view.officeapps.live.com/op/view.aspx?src=${this.fileId}`;
    //   // "https://view.officeapps.live.com/op/view.aspx?src=" + process.env.VUE_APP_BASE_PREVIEW + this.fileId
    // }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.loadFile();
    },
    loadFile() {
      // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
      let link = this.fileId;
      const type = link.substring(link.lastIndexOf(".") + 1);

      // console.log(!link.startsWith('/files') && !link.startsWith('http'))
      if (!link.startsWith("/files") && !link.startsWith("https")) {
        link = "/files/" + link;
      }
      if (this.isImg(type)) {
        this.showImg = true;
        this.imgUrl = link;
        this.loading = false;
      } else if (this.isPdf(type)) {
        this.isShowPdf = true;
        this.src = link;
        // const fileName = link.substring(0, link.lastIndexOf(".") + 1) + "pdf";
        // console.log(fileName, "name");
        // var loadingTask = pdf.createLoadingTask({
        //   url: fileName,
        //   httpHeaders: this.header
        // });
        // this.src = loadingTask;
        // // console.log(loadingTask)
        // loadingTask.promise.promise
        //   .then(pdf => {
        //     this.pageCount = pdf.numPages;
        //     console.log("总页数" + pdf.numPages);
        //     this.loading = false;
        //   })
        //   .catch(err => {
        //     this.loading = false;
        //     this.$message({
        //       message: "加载失败！错误代码：" + err.details,
        //       type: "error",
        //       duration: 5 * 1000
        //     });
        //   });
        this.loading = false;
      } else if (this.isOffice(type)) {
        this.isShowOffice = true;
        this.src = `https://view.officeapps.live.com/op/view.aspx?src=${link}`;
        this.loading = false;
      }
    },
    isImg(type) {
      if (this.imgType.indexOf(type) !== -1) {
        return true;
      }
      return false;
    },
    isPdf(type) {
      if (this.pdfType.indexOf(type) !== -1) {
        return true;
      }
      return false;
    },
    isOffice(type) {
      if (this.officeType.indexOf(type) !== -1) {
        return true;
      }
      return false;
    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      // console.log(val)
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
        // console.log(this.currentPage)
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
        // console.log(this.currentPage)
      }
    },
    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    },
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf {
  background-color: #fff;
  // height: 600px;
  overflow: hidden;
  min-height: 500px;
  // overflow-y: auto;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(57, 70, 100, 0.8);
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: #ededed;
  }
}
.pdf-box {
  width: 100%;
  overflow: hidden;
}
.img-box {
  text-align: center;
}
.img {
  // width: 100%;
  max-width: 1000px;
}
.pdf-img {
  width: 100%;
  border: 1px solid #dcdfe6;
}
</style>
